<template>
    <div>
        <psi-page-title
            :title="page.title"
            :icon="page.icon"
            :breadcrumbs="breadcrumbs"
        ></psi-page-title>
        <account-tool-bar></account-tool-bar>
        <vue-page-transition name="fade-in-right">
            <router-view class="mt-4"> </router-view>
        </vue-page-transition>
    </div>
</template>
<script>
export default {
    name: "my-account",
    components: {
        AccountToolBar: () =>
            import("@components/account/components/AccountToolBar"),
    },
    data() {
        return {
            page: {
                title: "My Account",
                icon: "mdi-account-cog",
            },
            breadcrumbs: [
                {
                    text: "Home",
                    disabled: false,
                    to: "#",
                },
                {
                    text: "Dashboard",
                    disabled: true,
                },
            ],
        };
    },
    watch: {
        $route: {
            immediate: true,
            deep: true,
            handler(to) {
                this.breadcrumbs.splice(1, 1, {
                    text: to.name,
                    disabled: true,
                });
            },
        },
    },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.25s ease-out;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>